$(function () {

    //выцепляем яблоки, вешаем класс
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        $("body").addClass("ios")
    }

    //поиск в шапке
    $(".header__search_button").click(function () {
        $(".header__search_form").addClass("header__search_form--open");
        $(".header__search_field").focus();
    });

    $(".header__search_close").click(function () {
        $(".header__search_form").removeClass("header__search_form--open");
    });

    //дропдауны
    function toggleDropdown(item) {
        $(item)
            .toggleClass("dropdowns__button--opened")
            .next().slideToggle(200);
    }

    $(".dropdowns__button").on("click", function (e) {

        if (!$(this).hasClass("dropdowns__button--opened")) {
            $(".dropdowns__button--opened").map(function (i, item) {
                toggleDropdown(item);
            });
        }

        toggleDropdown(this);

    });

    //счетчик
    var ods = document.querySelectorAll(".odometer");

    if (ods.length) {
        ods.forEach(function (od) {
            var count = od.dataset.count;
            od = new Odometer({
                el: od,
                value: od.innerHTML,
                format: '( ddd),dd'
            });
            od.update(count);
        });
    }

    //поля форм с подсказками
    if ($(".js_field").length) {

        var $inputs = $("input", ".js_field");
        var $textareas = $("textarea", ".js_field");

        var fn = function () {
            if ($(this).val().trim() !== "") {
                $(this).closest(".form__group").addClass("form__group_filled");
            }

            $(this).on("focus", function () {
                $(this).closest(".form__group").addClass("form__group_filled");
            });

            $(this).on("blur", function () {
                if ($(this).val().trim() === "")
                    $(this).closest(".form__group").removeClass("form__group_filled");
            });
        };

        $inputs.each(fn);

        $textareas.each(fn);
    }

    //закрываем алерт после успешной отправки формы
    $(".feedback__complete_close, .feedback__complete_good").click(function () {
        $(".feedback__complete").removeClass("feedback__complete--visible");
    });

    //закрываем элементы по escape
    $(document).keyup(function (event) {
        if (event.which == '27') {
            $(".feedback__complete").removeClass("feedback__complete--visible");
            $(".header__search_form").removeClass("header__search_form--open");
        }
    });

    //мобильное меню
    $('.btn_burger').click(function () {
        $(this).toggleClass('btn_burger--close');
        if ($(this).hasClass('btn_burger--close')) {
            $('.mobile_menu').addClass('mobile_menu--opened');
            $.lockBody();
        }
        else {
            $('.mobile_menu').removeClass('mobile_menu--opened');
            $.unlockBody();
        }
    });

    $.lockBody = function () {
        var scrollBarWidth = window.innerWidth - document.body.offsetWidth;
        document.body.style.margin = '0px ' + scrollBarWidth + 'px 0px 0px';
        document.body.style.overflow = 'hidden';
    };

    $.unlockBody = function () {
        document.body.style.margin = '';
        document.body.style.overflow = '';
    };

    //tables wrapper
    var $contentTables = $("table", ".text");

    if ($contentTables.length) {
        $contentTables.each(function () {
            $(this).wrap('<div class="table-wrap">');
        });
    }

    //галереи
    $('.gallery').lightGallery({
        selector: 'a'
    });

    //табы
    (function ($) {
        $(".tabs__categories").find("> li:eq(0)").addClass("current");

        $(".tabs__categories li a").click(function (g) {
            var tab = $(this).closest(".tabs"),
                index = $(this).closest("li").index();

            tab.find(".tabs__categories > li").removeClass('current');
            $(this).closest("li").addClass("current");

            tab.find(".tab__content").find(".tabs__item").not(".tabs__item:eq(" + index + ")").slideUp(100);
            tab.find(".tab__content").find(".tabs__item:eq(" + index + ")").slideDown(100);

            g.preventDefault();
        });
    })(jQuery);

    // Плавный скроллинг до якоря
    var documentIsScrolled = false;
    $(".js-slide_to").on("click", function (e) {
        var scrollTo = $($(this).attr("href"));
        if (scrollTo.length) {
            var scrollSize = scrollTo.offset().top,
                diff = $(window).scrollTop() - scrollSize;

            if (!documentIsScrolled) {

                documentIsScrolled = true;
                if (diff > 20 || diff < -20) {

                    $("body, html").animate({
                        scrollTop: scrollSize - 60
                    }, 400, function () {
                        documentIsScrolled = false;
                    });
                } else {
                    documentIsScrolled = false;
                }
            }

            if(this.dataset.title) {
                scrollTo.find('textarea[name=comment]').focus().text(this.dataset.title);
            }
        }

        e.preventDefault();
    });

    //аккордион скрытых подробностей
    $('.js-fade_btn').click(function () {
        var $this = $(this);
        $this.siblings('.js-fade_content').slideToggle(250);
        $this.toggleClass('js-fade_btn--hide');
        if ($this.hasClass('js-fade_btn--hide')) {
            $this.text('Скрыть подробности');
        } else {
            $this.text('Показать подробности');
        }
    });

    if (TurboForm) {
        $('.js-form').map(function (i, form) {
            var uploadContainer = form.querySelector('.upload');
            if (uploadContainer) {
                var uploader = (new TurboUploader(uploadContainer));
            }
            form = new TurboForm(form, {
                errorMsgClass: 'form__group--error',
                showErrorAfterInput: true,
                fieldLineClass: 'form__group'
            });
            form.showErrorAfterInput = function (parent, field, filedName) {
                $(parent).addClass(this.errorMsgClass);
                $(parent).find('.form__error_text').text(typeof this.errors[filedName] === "object" ? this.errors[filedName].message : this.errors[filedName]);
            };
            form.hideErrorsForInputs = function () {
                var labels = this.form.querySelectorAll('.' + this.errorMsgClass);
                var self = this;
                labels.forEach(function (item) {
                    item.classList.remove(self.errorMsgClass);
                });
            };
            form.showSuccessMessage = function (message) {
                var container = this.form.closest('.js-form-container');
                var self = this;
                if (container) {
                    var successWindow = container.querySelector('.feedback__complete');
                    if (successWindow) {
                        successWindow.querySelector('.feedback__complete_text').innerHTML = message;
                        successWindow.classList.add('feedback__complete--visible');
                        $(successWindow)
                            .find('.feedback__complete_good, .feedback__complete_close')
                            .one('click', function (e) {
                                // $(container).slideUp();
                                self.reset();
                                if(uploader) {
                                    uploader.reset();
                                }
                            });
                    } else {
                        alert(message);
                    }
                }
            };
        })
    }
});
